import React, { Component, Fragment } from 'react'
import store from "store"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import Cart from './Cart';
export class BrandData extends Component {
  state = {
    items: [],
    cartProducts: [],
  }

  FetchData = () => {

    let urlarr = document.location.pathname.split("/")
    let slug = urlarr[2];
      if (slug=="") {
        slug = urlarr[urlarr.length - 2]
      }

    // get brand data
    axios
      .post(
        "https://www.iamrachit.in/klsons/appapi/items",
        JSON.stringify({
          service_request: {
            params: {
              cat_id: "16",
              brand_id: this.props.brandid ?this.props.brandid: String(slug),
            },
            request_info: {
              source_type: "android",
            },
          },
          version: "1.0",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        console.log("brand_id",this.props.brandid);
        console.log("brands=>", res.data)
        this.setState({
          ...this.state,
          items: res.data.items,
        })
      })
  }

  componentDidMount() {
    // match state to added cart products
    if (store.get("products") && store.get("products").length > 0) {
      this.setState({
        ...this.state,
        products: store.get("products"),
      })
    }
    console.log("brand_id",this.props.brandid);
   
    // fetch brand data from api
    this.FetchData()
  }

  handleClick = data => {
    this.props.ModalData(data)
    // store.set("item",data)
  }

  handleCheckBox = e => {}

  AddtoEnq = branddata => {
    console.log("BrandData", branddata)
    this.props.Addtoenq((branddata = branddata))
  }

  
  AddToEnqCheckbox = data => {
    this.props.AddToEnqCheckbox(data)
  }
  render() {
    return (
      <>
        {this.state.items.map(
          (
            {
              item_name,
              item_id,
              rearwheel_size,
              finaldrive_size,
              item_desc,
              rearwheel_img,
              finaldrive_img,
            },
            index
          ) => {
            return (
              <Fragment key={item_id}>
                <tr>
                  <td scope="row">{index + 1}</td>
                  <td>{item_name}</td>
                  <td>{rearwheel_size}</td>
                  <td>{finaldrive_size}</td>

                  {this.props.userCartPref != "addtocart" && (
                    <td>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                          onChange={e => {
                            console.log(e.target.checked)
                            if (e.target.checked) {
                              this.AddToEnqCheckbox({
                                item_name,
                                item_id,
                                rearwheel_size,
                                finaldrive_size,
                                item_desc,
                                rearwheel_img,
                                finaldrive_img,
                              })
                            } else {
                              this.props.HandleDeleteCheckbox(
                                item_id,
                                item_name
                              )
                            }
                          }}
                        />
                      </div>
                    </td>
                  )}
                  {this.props.userCartPref != "checkbox" && (
                    <td>
                      <button
                        className="btn btn-primary"
                        style={{ padding: ".45rem .75rem", minWidth: "5rem" }}
                        onClick={() =>
                          this.handleClick({
                            item_name,
                            item_id,
                            rearwheel_size,
                            finaldrive_size,
                            item_desc,
                            rearwheel_img,
                            finaldrive_img,
                          })
                        }
                        key={item_id}
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        Enquire Now
                      </button>
                    </td>
                  )}

                  {/* <Cart item_name={item_name} Addtoenq={this.AddtoEnq} /> */}
                </tr>
              </Fragment>
            )
          }
        )}
      </>
    )
  }
}

export default BrandData
