import React, { useState, useEffect } from "react"
import Layout from "../Components/reusable/Layout"
import Banner from "../Components/reusable/Banner"
import store from "store"
import { BrandContext } from "../Components/context/themeContext"
import BrandData from "../Components/brand/BrandData"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import AxiosRequest from "../Components/Utility/AxiosRequest"
import { brands_url } from "../Components/Utility/Url"
import { navigate, Link } from "gatsby"

function AllBrands() {
  const [modalData, setModalData] = useState({
    item_id: "",
    cat_id: "16",
    brand_id: 1,
    item_name: "",
    item_desc: "",
    rearwheel_size: "",
    rearwheel_img: "",
    finaldrive_size: "",
    finaldrive_img: "",
  })

  const [userCartPref, setuserCartPref] = useState("")

  const [brandId, setBrandId] = useState(0)

  const [otherData, setOtherData] = useState({
    msg: "",
    img: null,
  })

  const [message, setMessage] = useState("")
  const [selectedFile, setselectedFile] = useState(null)
  // const [selectedOtherFile, setselectedOtherFile] = useState(null)

  const [quantity, setquantity] = useState(100)

  const [products, setProducts] = useState([])
  //  cart products
  const [cartProducts, setCartProducts] = useState([])
  // other brands
  const [OtherBrands, setOtherBrands] = useState([])

  // other brands
  const [CurrentBrandTitle, setCurrentBrandTitle] = useState("")

  const handleModalClick = data => {
    setModalData({ ...modalData, ...data })
  }

  const inc = () => {
    setquantity(quantity + 100)
  }
  const dec = () => {
    if (quantity > 1) {
      setquantity(quantity - 100)
    }
  }

  const handleChange = e => {
    setquantity(Number(e.target.value))
  }

  const AddToEnq = (value = 100) => {
    store.set("usercartpref", {
      pref: "addtocart",
    })
    setuserCartPref("addtocart")
    toast.success(`${modalData.item_name} added for enquiry`, {
      autoClose: 1000,
    })
    let newProduct = []

    var myProduct = {
      name: modalData.item_name,
      quantity: quantity,
      message: message,
      via: "addtocart",
    }

    if (store.get("products")) {
      newProduct = store.get("products")
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    } else {
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    }
    setquantity(100)
  }

  const handleTransfer = (brand, id) => {
    store.set("brand", { brand, id })
    navigate("/brands", { state: { brand } })
  }

  const AddToEnqCheckbox = (data, value = 100) => {
    store.set("usercartpref", {
      pref: "checkbox",
    })
    setuserCartPref("checkbox")
    toast.success(`${data.item_name} added for enquiry`, {
      autoClose: 1000,
    })
    let newProduct = []

    var myProduct = {
      name: data.item_name,
      quantity: quantity,
      message: message,
      id: data.item_id,
      via:"checkbox"
    }

    if (store.get("products")) {
      newProduct = store.get("products")
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    } else {
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    }
    setquantity(100)
  }

  useEffect(() => {
    // set user cart preference checkbox or addtocart
    


    let urlarr = document.location.pathname.split("/")
    let slug = urlarr[2]
    setBrandId(slug)
    console.log("slug => ",urlarr, slug)

    if (store.get("products") && store.get("products").length > 0) {
      setCartProducts(store.get("products"))
      // set user cart pref
      setuserCartPref(store.get("products")[0].via)
    }else{
      setuserCartPref("")
    }

    AxiosRequest(
      brands_url,
      {
        cat_id: "16",
      },
      res => {
        setOtherBrands(res.brands)
        res.brands.map(brand => {
          if (brand.brand_id == slug) {
            setCurrentBrandTitle(brand.brand_title)
          }
        })
      }
    )
  }, [brandId,userCartPref])

  const HandleDeleteCheckbox = (id, item_name) => {
    toast.error(`${item_name} removed from enquiry`, {
      autoClose: 1000,
    })

    
    const newdata = store.get("products").filter(item => item.id !== id)
    console.log("new after delete=>", newdata)
    store.set("products", newdata)
    setCartProducts(newdata)
    if (!store.get("products") || store.get("products").length<=0) {
      setuserCartPref("")
    } 
    return newdata
  }



  const AddAnotherToEnq = (e, value = 100) => {
    toast.error(`${modalData.item_name} added for enquiry`, {
      autoClose: 1000,
    })
    let newProduct = []

    var myProduct = {
      name: modalData.item_name,
      quantity: quantity,
      message: message,
    }

    if (store.get("products")) {
      newProduct = store.get("products")
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    } else {
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    }
    setquantity(100)
  }

  const handleOtherSubmit = e => {
    e.preventDefault()
    console.log("otherdata = ", otherData)

    if (otherData.img) {
      sendImageApi()
    }

    toast.error(`${otherData.msg} added for enquiry`, {
      autoClose: 1000,
    })

    let newProduct = []

    var myProduct = {
      name: otherData.msg,
      quantity: "",
      message: otherData.img ? otherData.img.name : "",
    }

    if (store.get("products")) {
      newProduct = store.get("products")
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    } else {
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    }
    setquantity(100)
  }

  
  const sendImageApi = () => {
    // Create an object of formData
    const formData = new FormData()

    // Update the formData object
    formData.append("filedata", otherData.img, otherData.img.name)

    // Details of the uploaded file
    console.log(otherData.img)

    // Request made to the backend api
    // Send formData object
    axios
      .post("https://www.iamrachit.in/klsons/appapi/upload_image", formData)
      .then(function (response) {
        console.log("response data=> ", response)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  return (
    <BrandContext.Consumer>
      {value => {
        return (
          <Layout>
            {/* {store.get("brand")?store.get("brand").brand:"products"} */}
            <Banner
              title={
                "Automotive Sprockets"
              }
            />
            <ToastContainer />
            <section className="container">
              {
                OtherBrands.map(({ brand_title, brand_id, brand_img }) => {
                      return (
                        <div className="row" key={brand_id}>
                          <div className="col-md-12 pt-3 accordion">
                            <a
                              className={
                                brandId == brand_id
                                  ? "btn btn-primary text-left"
                                  : "btn btn-primary text-left collapsed"
                              }
                              data-toggle="collapse"
                              href={"#collapseExample" + brand_id}
                              role="button"
                              aria-controls="collapseExample"
                              style={{ width: "100%" }}
                            >
                              {brand_title}
                            </a>
                          </div>
                          <div
                            className={
                              brandId == brand_id
                                ? "col-md-12 pb-2 collapse show"
                                : "col-md-12 pb-2 collapse"
                            }
                            id={"collapseExample" + brand_id}
                          >
                            <table className="table table-responsive-md  .table-bordered text-center">
                              <thead>
                                <tr>
                                  <th scope="col text-center">S No</th>
                                  <th scope="col text-center">
                                    PRODUCT / MODEL NAME
                                  </th>
                                  <th scope="col text-center">
                                    REAR WHEEL SPROCKET
                                  </th>
                                  <th scope="col text-center">
                                    FINAL DRIVE SPROCKET
                                  </th>
                                  {userCartPref != "addtocart text-center" && (
                                    <th scope="col">GENERAL ENQUIRY</th>
                                  )}
                                  {userCartPref != "checkbox text-center" && (
                                    <th scope="col">
                                      INDIVIDUAL ENQUIRY <br />
                                      WITH QUANTITY
                                    </th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row"></th>
                                  <td></td>
                                  <td
                                    className="text-center"
                                    style={{
                                      color: "#CD2D1D",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Teeth
                                  </td>
                                  <td
                                    className="text-center"
                                    style={{
                                      color: "#CD2D1D",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Teeth
                                  </td>
                                  <td></td>
                                </tr>

                                {/* <tr>
                                <th scope="row">1</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                            </tr> */}
                                <BrandData
                                  ModalData={handleModalClick}
                                  Addtoenq={AddToEnq}
                                  HandleDeleteCheckbox={HandleDeleteCheckbox}
                                  AddToEnqCheckbox={AddToEnqCheckbox}
                                  dec={dec}
                                  inc={inc}
                                  quantity={quantity}
                                  handleChange={handleChange}
                                  brandid={brand_id}
                                  userCartPref={userCartPref}
                                />

                                {/* <ModalView modalData={modalData}/> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )
                    })
              }
              
              <div
                                  className="modal fade"
                                  id="exampleModalCenter"
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="exampleModalCenterTitle"
                                  aria-hidden="true"
                                >
                                  <div
                                    className="modal-dialog modal-dialog-centered"
                                    role="document"
                                  >
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLongTitle"
                                        >
                                          {modalData.item_name}
                                        </h5>
                                        <button
                                          onClick={() => {
                                            setquantity(100)
                                          }}
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="container-fluid">
                                          {/* ///////////////// fix this view */}

                                          {Condition(modalData)}

                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="form-group">
                                                <label>
                                                  How many quantity ?
                                                </label>
                                                <br />
                                                <div
                                                  className="mb-1 quantity mr-2"
                                                  style={{
                                                    position: "relative",
                                                    width: "14rem",
                                                  }}
                                                >
                                                  <input
                                                    onChange={handleChange}
                                                    value={quantity}
                                                    className="form-control"
                                                    type="number"
                                                    id="quantity"
                                                    min={"100"}
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  />
                                                  <button
                                                    onClick={dec}
                                                    className=" mr-2 dec"
                                                    style={{
                                                      border: "none",
                                                      color: "black",
                                                      borderRadius: "5%",
                                                      padding: "0",
                                                      minWidth: "40px",
                                                      position: "absolute",
                                                    }}
                                                    aria-labelledby="decrement"
                                                  >
                                                    -
                                                  </button>
                                                  <button
                                                    onClick={inc}
                                                    className=" mr-2 inc"
                                                    style={{
                                                      border: "none",
                                                      color: "black",
                                                      borderRadius: "5%",
                                                      padding: "0",
                                                      minWidth: "40px",
                                                      position: "absolute",
                                                    }}
                                                    aria-labelledby="increment"
                                                  >
                                                    +
                                                  </button>
                                                </div>
                                              </div>
                                              <div class="form-group">
                                                <label htmlFor="message">
                                                  Message
                                                </label>
                                                <textarea
                                                  class="form-control"
                                                  onChange={e =>
                                                    setMessage(e.target.value)
                                                  }
                                                  value={message}
                                                  id="message"
                                                  rows="2"
                                                ></textarea>
                                              </div>
                                              <div class="form-group">
                                                <label htmlFor="file">
                                                  Add Attachment
                                                </label>
                                                <input
                                                  type="file"
                                                  onChange={e => {
                                                    setselectedFile(
                                                      e.target.files[0]
                                                    )
                                                    console.log(
                                                      "selected file=> ",
                                                      selectedFile
                                                    )
                                                  }}
                                                  class="form-control-file"
                                                  id="file"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <h6>Rearwheel_size</h6>
        <p>{modalData.rearwheel_size}</p>
        <h6>finaldrive_size</h6>
        <p>{modalData.finaldrive_size}</p> */}
                                      </div>
                                      <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-secondary" >Close</button> */}
                                        <button
                                          onClick={AddToEnq}
                                          type="button"
                                          className="btn btn-primary"
                                          data-dismiss="modal"
                                        >
                                          Add to Enquiry Cart
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              
              
              <div className="row py-5">
                <div className="col-md-12">
                  <h3>Others</h3>
                </div>
                <div className="col-md-12">
                  <form onSubmit={handleOtherSubmit}>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        onChange={e =>
                          setOtherData({
                            ...otherData,
                            msg: "other-" + e.target.value,
                          })
                        }
                        id="addOther"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="quantity">Attachment</label>
                      <input
                        type="file"
                        onChange={e =>
                          setOtherData({
                            ...otherData,
                            img: e.target.files[0],
                          })
                        }
                        className="form-control"
                        id="quantity"
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                  </form>
                </div>
              </div>
            </section>

           
          </Layout>
        )
      }}
    </BrandContext.Consumer>
  )
}

function Condition(modalData) {
  if (modalData.rearwheel_img || modalData.finaldrive_img) {
    return WithImageView(modalData)
  } else if (modalData.item_desc) {
    return WithoutImageView(modalData)
  } else {
    return onlySize(modalData)
  }
}

function WithImageView(modalData) {
  return (
    <div className="row">
      <div className="col-6">
        <div>
          <img
            src={`https://www.iamrachit.in/klsons/uploads/${modalData.rearwheel_img}`}
            alt=""
            width="100%"
          />
        </div>
        <div>
          <img
            src={`https://www.iamrachit.in/klsons/uploads/${modalData.finaldrive_img}`}
            alt=""
            width="100%"
          />
        </div>
      </div>
      <div className="col-6">
        <p>{modalData.item_desc}</p>
        <p>
          REAR WHEEL SPROCKET (teeth):
          {modalData.rearwheel_size}
        </p>
        <p>
          FINAL DRIVE SPROCKET (teeth):
          {modalData.finaldrive_size}
        </p>
      </div>
    </div>
  )
}

function WithoutImageView(modalData) {
  return (
    <div className="row">
      <div className="col-6">
        <p>{modalData.item_desc}</p>
      </div>
      <div className="col-6">
        <p>
          REAR WHEEL SPROCKET (teeth):
          {modalData.rearwheel_size}
        </p>
        <p>
          FINAL DRIVE SPROCKET (teeth):
          {modalData.finaldrive_size}
        </p>
      </div>
    </div>
  )
}

function onlySize(modalData) {
  return (
    <div className="row">
      <div className="col-12">
        <p>
          REAR WHEEL SPROCKET (teeth):
          {modalData.rearwheel_size}
        </p>
        <p>
          FINAL DRIVE SPROCKET (teeth):
          {modalData.finaldrive_size}
        </p>
      </div>
    </div>
  )
}

export default AllBrands
