import React,{useState} from 'react'
import store from "store"
import { ToastContainer, toast } from 'react-toastify';
function Cart({ item_name, Addtoenq }) {
  const [quantity, setquantity] = useState(1)

  const [products, setProducts] = useState([])

  const inc = () => {
    setquantity(quantity + 1)
  }
  const dec = () => {
    if (quantity > 1) {
      setquantity(quantity - 1)
    }
  }

  const handleChange = e => {
    setquantity(Number(e.target.value))
  }

  const AddToEnq = (value = 1, message = "") => {
    toast.error(`${item_name} added for enquiry`, { autoClose: 1000 })
    let newProduct = []

    const myProduct = {
      name: item_name,
      quantity: quantity,
      message,
    }

    if (store.get("products")) {
      newProduct = store.get("products")
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    } else {
      newProduct.push(myProduct)
      store.set("products", newProduct)
      setProducts(newProduct)
    }
    setquantity(1)
  }
  return (
    <>
      <td>
        <div
          className=" quantity mr-2"
          style={{
            position: "relative",
            width: "5rem",
            padding: "1px 2.5px 2.5px 2.5px",
          }}
        >
          <input
            onChange={handleChange}
            value={quantity}
            className="form-control"
            type="number"
            id="quantity"
            min={"1"}
            style={{ textAlign: "center", height: "100%", width: "100%" }}
          />
          <button
            onClick={dec}
            className="btn dec"
            style={{
              border: "none",
              color: "black",
              borderRadius: "5%",
              padding: "0",
              position: "absolute",
              minWidth: "20px",
              top: "0.8rem",
            }}
            aria-labelledby="decrement"
          >
            -
          </button>
          <button
            onClick={inc}
            className="btn inc"
            style={{
              border: "none",
              color: "black",
              borderRadius: "5%",
              padding: "0",
              position: "absolute",
              minWidth: "20px",
              top: "-4px",
            }}
            aria-labelledby="increment"
          >
            +
          </button>
        </div>
        <button
          onClick={() => {
            Addtoenq({quantity,item_name});
          }}
          type="button"
          className="btn btn-primary"
          
        >
          Add
        </button>
      </td>
    </>
  )
}

export default Cart
